import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import app from './firebase';
import Home from './Pages/Home';
import LogIn from './Pages/LogIn';
import PulseLoader from 'react-spinners/PulseLoader';
import { connect } from 'react-redux';
import { fireStoreGetConfig, fireStoreGetCurrentUser } from './redux/actions/fireStoreAction';
import { getAllDevicesInfo } from './redux/actions/allDevicesInfoAction';

class App extends Component {
	state = { loading: true, authenticated: false };

	componentDidMount = () => {
		app.auth().onAuthStateChanged(async (user) => {
			this.setState({ loading: true });
			if (user) {
				await this.props.fireStoreGetConfig();
				await this.props.fireStoreGetCurrentUser();
				await this.props.getAllDevicesInfo();
				this.setState({
					authenticated: true,
					loading: false,
				});
			} else {
				this.setState({
					authenticated: false,
					loading: false,
				});
			}
		});
	};

	renderLoading = () => (
		<div
			style={{
				flex: 1,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh',
			}}
		>
			<PulseLoader size={20} color={'#49b9ff'} />
		</div>
	);

	render() {
		const { authenticated, loading } = this.state;

		return loading ? (
			this.renderLoading()
		) : (
			<Router>
				<PrivateRoute path="/" component={Home} authenticated={authenticated} />
				<Route path="/login" component={LogIn} />
			</Router>
		);
	}
}

const mapDispatchToProps = {
	fireStoreGetConfig,
	fireStoreGetCurrentUser,
	getAllDevicesInfo,
};

export default connect(null, mapDispatchToProps)(App);
