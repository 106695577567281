import { combineReducers } from 'redux';
import fireStoreReducer from './fireStoreReducer';
import selectedFeedReducer from './selectedFeedReducer';
import allDevicesInfoReducer from './allDevicesInfoReducer';
import feedDataReducer from './feedDataReducer';
import shadowDataReducer from './shadowDataReducer';

export default combineReducers({
	fireStoreReducer,
	selectedFeedReducer,
	allDevicesInfoReducer,
	feedDataReducer,
	shadowDataReducer
});
