import { GET_CONFIG, GET_CURRENT_USER } from '../actions/fireStoreAction';

const initState = {
	fireStoreConfig: null,
	fireStoreCurrentUser: null,
};

const fireStoreReducer = (state = initState, action) => {
	switch (action.type) {
		case GET_CONFIG:
			return { ...state, fireStoreConfig: action.fireStoreConfig };
		case GET_CURRENT_USER:
			return { ...state, fireStoreCurrentUser: action.fireStoreCurrentUser };
		default:
			return state;
	}
};

export default fireStoreReducer;
