import React, { Component } from 'react';
import { Col, Form, Button, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FaRegCalendarAlt, FaGalacticSenate } from 'react-icons/fa';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect } from 'react-redux';
import { getSelectedFeed } from '../redux/actions/selectedFeedAction';

class GetDataSelection extends Component {

	state = {
		devices: this.props.allLocationInfo[0].devicesId,
		location: this.props.allLocationInfo[0].locationid,
		breakdown: 'minutes',
		startDate: moment().startOf('day'),
		endDate: moment().endOf('day'),
		ranges: {
			Today: [moment(), moment()],
			Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
			'Last 7 Days': [moment().subtract(6, 'days'), moment()],
			'Last 30 Days': [moment().subtract(29, 'days'), moment()],
			'This Month': [moment().startOf('month'), moment().endOf('month')],
			'Last Month': [
				moment()
					.subtract(1, 'month')
					.startOf('month'),
				moment()
					.subtract(1, 'month')
					.endOf('month'),
			],
		},
		type: this.props.allLocationInfo[0].type,
		disableMinutes: false,
		disableHours: false,
		disableDays: false,
		disableWeeks: false,
		disableMonths: false,
		selectMin: true,
		selectMiddle: false,
		selectMax: false
	};

	submitSelectedRange = () => {
		const { location, startDate, endDate, breakdown } = this.state;
		const { allLocationInfo } = this.props;
		const startDateTimeStamp = moment(startDate).valueOf();
		const endDateTimeStamp = moment(endDate).valueOf();
		const devices = allLocationInfo.find(element => element.locationid == location).devicesId;
		const type = allLocationInfo.find(element => element.locationid == location).type;
		this.props.getSelectedFeed(
			devices,
			location,
			{
				startDate: startDateTimeStamp,
				endDate: endDateTimeStamp,
			},
			breakdown,
			type
		);
	};

	handleApply = (event, picker) => {
		this.setState({
			startDate: picker.startDate,
			endDate: picker.endDate,
		});
		const startDateTimeStamp = moment(picker.startDate).valueOf();
		const endDateTimeStamp = moment(picker.endDate).valueOf();
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

		const diffDays = Math.round(Math.abs((startDateTimeStamp - endDateTimeStamp) / oneDay));
		const dayMonth = 31
		const dayYear = 366
		if (diffDays <= dayMonth) {
			if (this.state.selectMin != true) {
				this.setState({
					selectMin: true,
					breakdown: "minutes",
					selectMiddle: false,
					selectMax: false
				})
			}
			this.setState({
				disableMinutes: false,
				disableHours: false,
				disableDays: false,
				disableWeeks: false,
				disableMonths: false
			});
		}
		else if (diffDays > dayMonth && diffDays <= dayYear) {
			if (this.state.selectMiddle != true) {
				this.setState({
					selectMiddle: true,
					breakdown: "days",
					selectMin: false,
					selectMax: false
				})
			}
			this.setState({
				disableMinutes: true,
				disableHours: true,
				disableDays: false,
				disableWeeks: false,
				disableMonths: false
			});
		}
		else {
			if (this.state.selectMax != true) {
				this.setState({
					selectMax: true,
					breakdown: "weeks",
					selectMin: false,
					selectMiddle: false
				})
			}
			this.setState({
				disableMinutes: true,
				disableHours: true,
				disableDays: true,
				disableWeeks: false,
				disableMonths: false
			});
		}
	};

	componentDidMount = () => {
		this.submitSelectedRange();
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.selectedFeed != this.props.selectedFeed) {
			this.setState({ location: this.props.selectedFeed.location })
		}
	};



	render() {
		const { allDeviceId, allDeviceInfo, allLocationId, allLocationInfo } = this.props;
		let start = this.state.startDate.format('DD MMM YY');
		let end = this.state.endDate.format('DD MMM YY');
		let label = start + ' - ' + end;
		if (start == end) {
			label = start;
		}

		return (
			<Col>
				<Form.Label>Get data from</Form.Label>
				<Form.Row>
					<Col md>
						<Form.Control
							as="select"
							// defaultValue={this.state.location}
							onChange={e => {
								this.setState({ location: e.target.value });
							}}
							value={this.state.location}
						>
							{allLocationInfo ? (
								allLocationInfo.map((location, index) => {
									return (
										<option key={`option${index}`} value={location.locationid}>
											{location.name}
										</option>
									);
								})
							) : (
									<option value="">Loading...</option>
								)}
						</Form.Control>
					</Col>
					<Col md>
						<DateRangePicker
							containerStyles={{ display: 'flex', marginBottom: '0.4rem' }}
							startDate={this.state.startDate}
							endDate={this.state.endDate}
							ranges={this.state.ranges}
							onApply={this.handleApply}
						>
							<InputGroup>
								<InputGroup.Append>
									<InputGroup.Text>
										<FaRegCalendarAlt />
									</InputGroup.Text>
								</InputGroup.Append>
								<FormControl value={label} readOnly style={{ backgroundColor: '#FFFFFF' }} />
							</InputGroup>
						</DateRangePicker>
					</Col>
					<Col md={3}>
						<Form.Control
							as="select"
							value={this.state.breakdown}
							onChange={e => {
								this.setState({ breakdown: e.target.value });
							}}
						>
							<option value="minutes" disabled={this.state.disableMinutes}>Breakdown by minute</option>
							<option value="hours" disabled={this.state.disableHours}>Breakdown by hour</option>
							<option value="days" disabled={this.state.disableDays}>Breakdown by day</option>
							<option value="weeks" disabled={this.state.disableWeeks}>Breakdown by week</option>
							<option value="months" disabled={this.state.disableMonths}>Breakdown by month</option>
						</Form.Control>
					</Col>
					<Col md={1}>
						<Button
							block
							variant="success"
							onClick={this.submitSelectedRange}
							disabled={this.props.loading}
						>
							{this.props.loading ? <Spinner animation="border" size="sm" /> : `Go`}
						</Button>
					</Col>
				</Form.Row>
			</Col>
		);
	}
}

const mapStateToProps = state => {
	return {
		allDeviceInfo: state.allDevicesInfoReducer.allDeviceInfo,
		allDeviceId: state.allDevicesInfoReducer.allDeviceId,
		allLocationInfo: state.allDevicesInfoReducer.allLocationInfo,
		allLocationId: state.allDevicesInfoReducer.allLocationId,
		selectedFeed: state.selectedFeedReducer.selectedFeed,
	};
};

const mapDispatchToProps = {
	getSelectedFeed,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GetDataSelection);
