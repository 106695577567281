import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import ReactMapboxGl, { Layer, Feature, Popup } from 'react-mapbox-gl';
import { connect } from 'react-redux';

const Map = ReactMapboxGl({
	accessToken: 'pk.eyJ1IjoicHJhY2hwYXdlZSIsImEiOiJjanlxdndybXcwNDZtM2RxcDlzenRrdzhsIn0.gvfTBIUhhBKvY2ZWBjj3Zw',
});

export default class Mapbox extends Component {
	state = {
		center: this.props.center,
		active: this.props.active,
		zoom: [10],
		data: this.props.data,
	};

	// componentDidUpdate = (prevProps, prevState) => {
	// 	if (this.props.allLocationInfo) {
	// 		if (prevProps.selectedFeed !== this.props.selectedFeed) {
	// 			if(this.props.selectedFeed.type=="door"){
	// 				const data1 = this.props.allLocationInfo.filter(location => location.type == "door");
	// 				const { location } = this.props.selectedFeed;
	// 				const currentIndex = data1.findIndex(element => element.locationid == location);
	// 				this.setState({
	// 					data: data1,
	// 					center: data1[currentIndex].position,
	// 					active: data1[currentIndex],
	// 				});
	// 			}
	// 			else{
	// 				this.setState({
	// 					data: [],
	// 					center: [null,null],
	// 					active: null,
	// 				});
	// 			}
	// 		} 
	// 	}
	// };

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.active != this.props.active) {
			this.setState({
				data: this.props.data,
				center: this.props.center,
				active: this.props.active,
			});
		}
	}

	render() {
		const { center, active, zoom } = this.state;
		return (
			<Col>
				<Map
					// eslint-disable-next-line
					style="mapbox://styles/mapbox/streets-v11"
					containerStyle={{
						height: '50vh',
						width: '100%',
						marginTop: '.5em',
					}}
					center={center}
					zoom={zoom}
					onClick={() => this.setState({ active: undefined })}
					movingMethod="jumpTo"
				>
					<Layer
						style={{ cursor: 'pointer' }}
						type="circle"
						id="marker"
						paint={{
							'circle-color': 'red',
							'circle-stroke-width': 1,
							'circle-stroke-color': 'white',
							'circle-stroke-opacity': 1,
						}}
					>
						{Object.keys(this.state.data).map((item, index) => (
							<Feature
								key={item}
								style={{ cursor: 'pointer' }}
								coordinates={this.state.data[item].position}
								onMouseEnter={() =>
									this.setState({
										active: this.state.data[item],
									})
								}
								onClick={() => {
									this.setState({
										center: this.state.data[item].position,
										zoom: [10],
										active: this.state.data[item],
									});
								}}
							/>
						))}
					</Layer>
					{active && (
						<Popup
							key={active.id}
							coordinates={active.position}
							onClick={() => this.props.changeLocation(this.state.active)}
							style={{ cursor: 'pointer' }}
						>
							<div
								style={{
									background: 'white',
									color: '#3f618c',
									fontWeight: 400,
									borderRadius: 2,
								}}
							>
								{active.name.toUpperCase()}
							</div>
						</Popup>
					)}
				</Map>
			</Col>
		);

	}
}

// const mapStateToProps = state => {
// 	return {
// 		allDeviceInfo: state.allDevicesInfoReducer.allDeviceInfo,
// 		allLocationInfo: state.allDevicesInfoReducer.allLocationInfo,
// 		selectedFeed: state.selectedFeedReducer.selectedFeed,
// 	};
// };

// export default connect(mapStateToProps)(Mapbox);
