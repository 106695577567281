import React from 'react';
import { Card, Form, Button, Container } from 'react-bootstrap';

const LogInView = ({ onSubmit }) => {
	return (
		<Container
			style={{
				alignItems: 'center',
				paddingTop: '2em',
				maxWidth: '400px',
				minHeight: '100vh',
			}}
		>
			<Card style={{ padding: '2em 1em', backgroundColor: '#49b9ff' }}>
				<h2 style={{ color: 'white' }}>ToiletCoin Login</h2>
				<br />
				<Form onSubmit={onSubmit}>
					<Form.Group controlId="formBasicEmail">
						<Form.Control type="email" placeholder="E-mail..." name="email" />
					</Form.Group>
					<Form.Group controlId="formBasicPassword">
						<Form.Control type="password" placeholder="Password..." name="password" />
					</Form.Group>
					<br />
					<Button variant={'success'} block type="submit">
						Log in
					</Button>
				</Form>
			</Card>
		</Container>
	);
};

export default LogInView;
