import { GET_ALL_DEVICE_INFO } from '../actions/allDevicesInfoAction';

const initState = {
	allDeviceInfo: null,
	allDeviceId: null,
	allLocationInfo: null,
	allLocationId: null
};

const allDevicesInfoReducer = (state = initState, action) => {
	switch (action.type) {
		case GET_ALL_DEVICE_INFO:
			return {
				...state,
				...{
					allDeviceInfo: action.allDeviceInfo,
					allDeviceId: action.allDeviceId,
					allLocationInfo: action.allLocationInfo,
					allLocationId: action.allLocationId
				},
			};

		default:
			return state;
	}
};

export default allDevicesInfoReducer;
