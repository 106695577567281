import app, { db } from '../../firebase';

export const GET_CONFIG = 'GET_CONFIG';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';

export const fireStoreGetConfigAction = ({ kairosUrl, userToken, gqlUrl }) => ({
	type: GET_CONFIG,
	fireStoreConfig: { kairosUrl, userToken, gqlUrl },
});

export const fireStoreGetCurrentUserAction = ({ username, uid }) => ({
	type: GET_CURRENT_USER,
	fireStoreCurrentUser: { username, uid },
});

export const fireStoreGetConfig = () => async dispatch => {
	const configData = await db.collection('config').get();
	//get all data from collection 'config'
	return configData.forEach(doc => {
		return dispatch(
			fireStoreGetConfigAction({
				kairosUrl: doc.data().kairosUrl,
				userToken: doc.data().userToken,
				gqlUrl: doc.data().gqlUrl
			})
		);
	});
};

export const fireStoreGetCurrentUser = () => async dispatch => {
	const { displayName, email, uid } = await app.auth().currentUser;
	return dispatch(
		fireStoreGetCurrentUserAction({
			username: displayName || email,
			uid,
		})
	);
};
