export const GET_RANGE_DATA = 'GET_RANGE_DATA';

export const getSelectedFeedAction = (devices, location, range, breakdown, type) => ({
	type: GET_RANGE_DATA,
	selectedFeed: { devices, location, range, breakdown, type },
});

export const getSelectedFeed = (devices, location, range, breakdown, type) => dispatch => {
	return dispatch(getSelectedFeedAction(devices, location, range, breakdown, type));
};
