import { GET_FEED_DATA } from '../actions/feedDataAction';

const initState = {
	currentFeed: null,
	revenueFeed: null,
	remoteFeed: null,
	peopleFeed: null,
	fingerPrintFeed: null,
	keycardFeed: null,
};

const feedDataReducer = (state = initState, action) => {
	switch (action.type) {
		case GET_FEED_DATA:
			return {
				...state,
				...{
					currentFeed: action.currentFeed,
					revenueFeed: action.revenueFeed,
					remoteFeed: action.remoteFeed,
					peopleFeed: action.peopleFeed,
					fingerPrintFeed: action.fingerPrintFeed,
					keycardFeed: action.keycardFeed,
				},
			};

		default:
			return state;
	}
};

export default feedDataReducer;
