import { GET_SHADOW_DATA } from '../actions/shadowDataAction';

const initState = {
	coinExchangeData: null,
};

const shadowDataReducer = (state = initState, action) => {
	switch (action.type) {
		case GET_SHADOW_DATA:
			return {
				...state,
				...{
					coinExchangeData: action.coinExchangeData,
				},
			};

		default:
			return state;
	}
};

export default shadowDataReducer;
