import axios from 'axios';
export const GET_SHADOW_DATA = 'GET_SHADOW_DATA';

export const getShadowDataAction = ({
	coinExchangeData,
}) => ({
	type: GET_SHADOW_DATA,
	coinExchangeData,
});

export const getShadowData = () => async (dispatch, getState) => {
	const { fireStoreReducer, selectedFeedReducer } = getState();
	const { gqlUrl, userToken } = fireStoreReducer.fireStoreConfig;
	const { devices } = selectedFeedReducer.selectedFeed;

    let queryData = "";
	devices.map((deviceid,index) => {
        queryData += `query${index}:shadow(deviceid:\"${deviceid}\"){deviceid data rev modified}`
    });
    const data = { "query":"{"+queryData+"}"};
    
	try {
		// axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
		// const response = await axios.post(kairosUrl, q_data);
		const request = {
			url: gqlUrl,
			method: 'post',
			headers: { Authorization: `Bearer ${userToken}` },
			data: data,
		};
        const response = await axios(request);
		let coinExchange = [];
		devices.map((device, index) => {
            const value = { deviceid: response.data.data[`query${index}`].deviceid, onStock: response.data.data[`query${index}`].data ? response.data.data[`query${index}`].data["On Stock"] : "-", changed: response.data.data[`query${index}`].data ? response.data.data[`query${index}`].data["Changed"]: "-" }
            coinExchange.push(value);
		});
		dispatch(
			getShadowDataAction({
				coinExchangeData: coinExchange,
			})
		);
	} catch (error) {
		console.error(error);
	}
};
