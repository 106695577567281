import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './redux/reducers';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import App from './App';
import './styles.scss';

let middleware = process.env.NODE_ENV == `development` ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

const store = createStore(rootReducer, middleware);

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);
