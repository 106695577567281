import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import app from '../firebase';
import withWindowDimensions from '../utils/withWindowDimensions';
import { connect } from 'react-redux';

const NavbarComponent = ({ isMobileSized, fireStoreCurrentUser }) => {
	const { username } = fireStoreCurrentUser;
	const signOut = async () => {
		try {
			await app.auth().signOut();
		} catch(error) {
			console.log(error)
		}
	};
	return (
		<Navbar style={{ backgroundColor: '#49b9ff', paddingHorizontal: '2rem' }}>
			<Navbar.Brand style={{ color: '#fff' }}>ToiletCoin Dashboard</Navbar.Brand>
			<Navbar.Toggle />
			<Navbar.Collapse className="justify-content-end">
				{!isMobileSized && <Navbar.Text style={{ color: '#fff' }}>user: {username} </Navbar.Text>}
				<Button variant="link" onClick={signOut} style={{ color: '#fff' }}>
					Log Out
				</Button>
			</Navbar.Collapse>
		</Navbar>
	);
};

const mapStateToProps = state => {
	return {
		fireStoreCurrentUser: state.fireStoreReducer.fireStoreCurrentUser,
	};
};

const wrappedNavbar = withWindowDimensions(NavbarComponent);

export default connect(mapStateToProps)(wrappedNavbar);
