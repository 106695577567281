import axios from 'axios';
export const GET_FEED_DATA = 'GET_FEED_DATA';

export const getFeedDataAction = ({
	currentFeed,
	revenueFeed,
	remoteFeed,
	peopleFeed,
	fingerPrintFeed,
	keycardFeed,
}) => ({
	type: GET_FEED_DATA,
	currentFeed,
	revenueFeed,
	remoteFeed,
	peopleFeed,
	fingerPrintFeed,
	keycardFeed,
});

export const getFeedData = () => async (dispatch, getState) => {
	const { fireStoreReducer, selectedFeedReducer } = getState();
	const { kairosUrl, userToken } = fireStoreReducer.fireStoreConfig;
	const { devices, range, breakdown, location } = selectedFeedReducer.selectedFeed;
	const optiontimestamp = {
		minutes: 1000 * 60 * 5,
		hours: 1000 * 60 * 60,
		days: 1000 * 60 * 60 * 24,
		weeks: 1000 * 60 * 60 * 24 * 7,
		months: 1000 * 60 * 60 * 24 * 31,
	};

	let attrList;
	let metrics = [];
	let oldBreakdown = breakdown;
	devices.forEach((device) => {
		attrList = [
			{ tag: 'Current Cash', aggregator: 'max' },
			{ tag: 'Remote', aggregator: 'last' },
			{ tag: 'People', aggregator: 'last' },
			{ tag: 'Fingerprint', aggregator: 'last' },
			{ tag: 'Keycard', aggregator: 'last' },
		];

		let newBreakdown = breakdown != 'minutes' ? 'hours' : breakdown;

		attrList.forEach((element) => {
			const metric = {
				name: device,
				group_by: [
					{
						name: 'tag',
						tags: ['attr'],
					},
				],
				tags: {
					attr: [element.tag],
				},
				aggregators: [
					{
						name: element.aggregator,
						sampling: {
							value: 1,
							unit: newBreakdown,
						},
					},
				],
			};
			metrics.push(metric);
		});
	});
	let start = range.startDate - optiontimestamp[oldBreakdown];
	let end = range.endDate;

	const q_data = {
		start_absolute: start,
		end_absolute: end,
		metrics: metrics,
	};
	try {
		const request = {
			url: kairosUrl,
			method: 'post',
			headers: { Authorization: `Bearer ${userToken}` },
			data: q_data,
		};
		const response = await axios(request);
		let currentFeedList = [];
		let revenueFeedList = [];
		let remoteFeedList = [];
		let peopleFeedList = [];
		let fingerPrintFeedList = [];
		let keycardFeedList = [];

		response.data.queries.forEach((query) => {
			if (query.results[0].tags.attr == 'Current Cash') {
				if (query.results[0].values.length > 0) {
					let currentFeed = { deviceId: query.results[0].name, value: query.results[0].values };
					let revenueFeed = { deviceId: query.results[0].name, value: [[query.results[0].values[0][0], 0]] };
					for (let index = 1; index < query.results[0].values.length; index++) {
						const current = query.results[0].values[index][1];
						const before = query.results[0].values[index - 1][1];
						if (current - before >= 0) {
							revenueFeed.value[revenueFeed.value.length] = [
								query.results[0].values[index][0],
								current - before,
							];
						} else {
							revenueFeed.value[revenueFeed.value.length] = [query.results[0].values[index][0], current];
							console.log('Revenue', current, before);
						}
						if (index == query.results[0].values.length - 1) {
							let i = 0;
							let lengthFeedi = currentFeed.value.length - 1;
							while (i < lengthFeedi) {
								if (currentFeed.value[0][0] >= range.startDate) {
									i += 1;
									break;
								} else {
									currentFeed.value.shift();
									lengthFeedi -= 1;
								}
							}
							currentFeedList.push(currentFeed);
							let j = 0;
							let lengthFeedj = revenueFeed.value.length - 1;
							while (j < lengthFeedj) {
								if (revenueFeed.value[0][0] >= range.startDate) {
									j += 1;
									break;
								} else {
									revenueFeed.value.shift();
									lengthFeedj -= 1;
								}
							}
							revenueFeedList.push(revenueFeed);
						}
					}
				}
			} else if (query.results[0].tags.attr == 'Remote') {
				if (query.results[0].values.length > 0) {
					let remoteFeed = { deviceId: query.results[0].name, value: [[query.results[0].values[0][0], 0]] };
					for (let index = 1; index < query.results[0].values.length; index++) {
						const current = query.results[0].values[index][1];
						const before = query.results[0].values[index - 1][1];
						if (current - before >= 0) {
							remoteFeed.value[remoteFeed.value.length] = [
								query.results[0].values[index][0],
								current - before,
							];
						} else {
							remoteFeed.value[remoteFeed.value.length] = [query.results[0].values[index][0], current];
						}
						if (index == query.results[0].values.length - 1) {
							let i = 0;
							let lengthFeed = remoteFeed.value.length - 1;
							while (i < lengthFeed) {
								if (remoteFeed.value[0][0] >= range.startDate) {
									i += 1;
									break;
								} else {
									remoteFeed.value.shift();
									lengthFeed -= 1;
								}
							}
							remoteFeedList.push(remoteFeed);
						}
					}
				}
			} else if (query.results[0].tags.attr == 'People') {
				if (query.results[0].values.length > 0) {
					let peopleFeed = {
						deviceId: query.results[0].name,
						value: [[query.results[0].values[0][0], query.resulldsf]],
					};
					for (let index = 1; index < query.results[0].values.length; index++) {
						const current = query.results[0].values[index][1];
						const before = query.results[0].values[index - 1][1];
						if (current - before >= 0) {
							peopleFeed.value[peopleFeed.value.length] = [
								query.results[0].values[index][0],
								current - before,
							];
						} else {
							peopleFeed.value[peopleFeed.value.length] = [query.results[0].values[index][0], current];
							console.log('People', current, before);
						}
						if (index == query.results[0].values.length - 1) {
							let i = 0;
							let lengthFeed = peopleFeed.value.length - 1;
							while (i < lengthFeed) {
								if (peopleFeed.value[0][0] >= range.startDate) {
									i += 1;
									break;
								} else {
									peopleFeed.value.shift();
									lengthFeed -= 1;
								}
							}
							peopleFeedList.push(peopleFeed);
						}
					}
				}
			} else if (query.results[0].tags.attr == 'Fingerprint') {
				if (query.results[0].values.length > 0) {
					let fingerPrintFeed = {
						deviceId: query.results[0].name,
						value: [[query.results[0].values[0][0], 0]],
					};
					for (let index = 1; index < query.results[0].values.length; index++) {
						const current = query.results[0].values[index][1];
						const before = query.results[0].values[index - 1][1];
						if (current - before >= 0) {
							fingerPrintFeed.value[fingerPrintFeed.value.length] = [
								query.results[0].values[index][0],
								current - before,
							];
						} else {
							fingerPrintFeed.value[fingerPrintFeed.value.length] = [
								query.results[0].values[index][0],
								current,
							];
						}
						if (index == query.results[0].values.length - 1) {
							let i = 0;
							let lengthFeed = fingerPrintFeed.value.length - 1;
							while (i < lengthFeed) {
								if (fingerPrintFeed.value[0][0] >= range.startDate) {
									i += 1;
									break;
								} else {
									fingerPrintFeed.value.shift();
									lengthFeed -= 1;
								}
							}
							fingerPrintFeedList.push(fingerPrintFeed);
						}
					}
				}
			} else if (query.results[0].tags.attr == 'Keycard') {
				if (query.results[0].values.length > 0) {
					let keycardFeed = { deviceId: query.results[0].name, value: [[query.results[0].values[0][0], 0]] };
					for (let index = 1; index < query.results[0].values.length; index++) {
						const current = query.results[0].values[index][1];
						const before = query.results[0].values[index - 1][1];
						if (current - before >= 0) {
							keycardFeed.value[keycardFeed.value.length] = [
								query.results[0].values[index][0],
								current - before,
							];
						} else {
							keycardFeed.value[keycardFeed.value.length] = [query.results[0].values[index][0], current];
						}
						if (index == query.results[0].values.length - 1) {
							let i = 0;
							let lengthFeed = keycardFeed.value.length - 1;
							while (i < lengthFeed) {
								if (keycardFeed.value[0][0] >= range.startDate) {
									i += 1;
									break;
								} else {
									keycardFeed.value.shift();
									lengthFeed -= 1;
								}
							}
							keycardFeedList.push(keycardFeed);
						}
					}
				}
			}
		});
		dispatch(
			getFeedDataAction({
				currentFeed: currentFeedList || [],
				revenueFeed: revenueFeedList || [],
				remoteFeed: remoteFeedList || [],
				peopleFeed: peopleFeedList || [],
				fingerPrintFeed: fingerPrintFeedList || [],
				keycardFeed: keycardFeedList || [],
			})
		);
	} catch (error) {
		console.error(error);
		dispatch(
			getFeedDataAction({
				currentFeed: [],
				revenueFeed: [],
				remoteFeed: [],
				peopleFeed: [],
				fingerPrintFeed: [],
				keycardFeed: [],
			})
		);
	}
};
