import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaMoneyBillAlt, FaArrowUp, FaArrowDown, FaUserFriends } from 'react-icons/fa';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import withWindowDimensions from '../utils/withWindowDimensions';
import moment from 'moment';

export const SmCard = props => {
	const smdata = props.smitem;
	const datavalue = props.datavalue || 0;
	const iconStyle = { style: { height: '1.5em', width: '1.5em' } };
	return (
		<Card style={{ padding: '.7em', color: 'white', backgroundColor: props.backgroundColor }}>
			<h6>{smdata.smtitle}</h6>
			<Row style={{ alignItems: 'center' }}>
				<Col>
					{smdata.iconType == 'max' ? (
						<FaArrowUp {...iconStyle} />
					) : smdata.iconType == 'min' ? (
						<FaArrowDown {...iconStyle} />
					) : smdata.iconType == 'money' ? (
						<FaMoneyBillAlt {...iconStyle} />
					) : (
									<FaUserFriends {...iconStyle} />
								)}
				</Col>
				<Col>
					<h3 style={{ textAlign: 'right' }}>{datavalue.value}</h3>
				</Col>
			</Row>
		</Card>
	);
};

const DataCard = props => {
	const data = props.item;
	const breakdown = props.breakdown;
	var dataObj = [];
	if (props.feedData) {
		var allValues = [];
		var multistation = false
		for (let k = 0; k < props.feedData.length; k++) {
			allValues = allValues.concat(props.feedData[k].value);
			if (k > 0) multistation = true;
		}
		var mapValues = [];
		for (let index = 0; index < allValues.length; index++) {
			let key = allValues[index][0];
			if (breakdown == "minutes") {
				key = moment(parseInt(allValues[index][0])).minutes(Math.round(moment(parseInt(allValues[index][0])).minutes() / 5) * 5);
			} else if (breakdown == "hours") {
				key = moment(parseInt(allValues[index][0])).minutes(0);
			}
			else if (breakdown == "days") {
				key = moment(parseInt(allValues[index][0])).hours(8).minutes(0);
			}
			else if (breakdown == "weeks") {
				key = moment(parseInt(allValues[index][0])).startOf('week').hours(1).minutes(0);
			}
			else if (breakdown == "months") {
				key = moment(parseInt(allValues[index][0])).startOf('month').hours(1).minutes(0);
			}
			const timestamp = key.valueOf().toString().substring(0, 8) + "00000";
			if (Object.keys(mapValues).includes(timestamp)) {
				if (multistation === false) {
					if (data.title === "Current chart") {
						mapValues[timestamp] = allValues[index][1];
					}
					else {
						mapValues[timestamp] += allValues[index][1];
					}
				}
				else {
					mapValues[timestamp] += allValues[index][1];
				}
			} else {
				mapValues[timestamp] = allValues[index][1];
			}
		}

		Object.keys(mapValues).sort().map(key => {
			dataObj.push({ xAxis: moment(parseInt(key)).format('D/M/YY HH:mm'), yAxis: mapValues[key] })
		});
	}

	return (
		<Col md={6}>
			<Card
				style={{
					backgroundColor: data.backgroundColor,
					color: 'white',
					padding: '0.9em',
					margin: '1.2em 0 0 0',
				}}
			>
				<h4 style={{ textAlign: 'center' }}>{data.title}</h4>
				<div style={{ width: '100%', height: 300 }}>
					<ResponsiveContainer>
						<AreaChart
							data={dataObj}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 60,
							}}
						>
							<Tooltip
								contentStyle={{ backgroundColor: 'rgba(0,0,0,0.8)', borderRadius: 5 }}
								labelStyle={{ fontWeight: 'bold' }}
								itemStyle={{ fontSize: '12px' }}
								formatter={(value, name, props) => {
									return [value, data.title.split(' ')[0]];
								}}
							/>
							<XAxis
								stroke="white"
								dataKey="xAxis"
								tick={{ fill: 'white' }}
								angle={-30}
								textAnchor="end"
							/>
							<YAxis stroke="white" tick={{ fill: 'white' }} />
							<Area type="monotone" dataKey="yAxis" stroke="white" fill="white" />

						</AreaChart>
					</ResponsiveContainer>
				</div>
			</Card>
			<Row>
				{data.detail.map((item, index) =>
					data.detail.length == 1 ? (
						<Col key={index} md={12} style={{ marginTop: '.7em' }}>
							<SmCard
								backgroundColor={data.backgroundColor}
								smitem={item}
								datavalue={props.datavalue[index]}
							/>
						</Col>
					) : (
							<Col
								key={index}
								md={6}
								style={{
									marginTop: '.7em',
									paddingLeft: !props.isMobileSized ? (index % 2 == 0 ? '15px' : '7.5px') : '15px',
									paddingRight: !props.isMobileSized ? (index % 2 !== 0 ? '15px' : '7.5px') : '15px',
								}}
							>
								<SmCard
									backgroundColor={data.backgroundColor}
									smitem={item}
									datavalue={props.datavalue[index]}
								/>
							</Col>
						)
				)}
			</Row>
			{/* <Row style={{ justifyContent: 'flex-end', marginRight: 1 }}>
				<CSVLink data={props.datavalue} filename={data.title}>
					<Button variant="outline-dark" style={{ marginTop: '.5em' }}>
						<FaFileCsv style={{ margin: '0 2px 2px 0' }} />
						Export to CSV
					</Button>
				</CSVLink>
			</Row> */}
		</Col>
	);
};
export default withWindowDimensions(DataCard);
