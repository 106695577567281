import React, { Component } from 'react';
import { Row, Container } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import NavBar from '../components/NavBar';
import DataCard from '../components/DataCard';
import GetDataSelection from '../components/GetDataSelection';
import Mapbox from '../components/Mapbox';
import moment from 'moment';
import { connect } from 'react-redux';
import { getAllDevicesInfo } from '../redux/actions/allDevicesInfoAction';
import { getFeedData } from '../redux/actions/feedDataAction';
import { getShadowData } from '../redux/actions/shadowDataAction';
import { getSelectedFeed } from '../redux/actions/selectedFeedAction';

const Data = [
	{
		title: 'Current chart',
		backgroundColor: '#51ABAD',
		detail: [{ smtitle: 'Total current', iconType: 'money' }],
	},
	{
		title: 'Revenue chart',
		backgroundColor: '#51ABAD',
		detail: [{ smtitle: 'Total revenue', iconType: 'money' }],
	},
	{
		title: 'People chart',
		backgroundColor: '#5E73F6',
		detail: [{ smtitle: 'Total People' }],
	},
	{
		title: 'Remote chart',
		backgroundColor: '#5E73F6',
		detail: [{ smtitle: 'Total Remote' }],
	},
	{
		title: 'Fingerprint chart',
		backgroundColor: '#5E73F6',
		detail: [{ smtitle: 'Total Fingerprint' }],
	},
	{
		title: 'Keycard chart',
		backgroundColor: '#5E73F6',
		detail: [{ smtitle: 'Total Keycard' }],
	},
	{
		title: 'Fingerprint chart',
		backgroundColor: '#5E73F6',
		detail: [{ smtitle: 'Total Fingerprint' }],
	},
];

class Home extends Component {
	state = {
		currentLast: null,
		revenueTotal: null,
		revenueMax: null,
		revenueMin: null,
		revenueAvg: null,
		peopleTotal: null,
		peopleMax: null,
		peopleMin: null,
		peopleAvg: null,
		remoteTotal: null,
		remoteMax: null,
		remoteMin: null,
		remoteAvg: null,
		fingerPrintTotal: null,
		fingerPrintMax: null,
		fingerPrintMin: null,
		fingerPrintAvg: null,
		keycardTotal: null,
		keycardMax: null,
		keycardMin: null,
		keycardAvg: null,
		table: null,
		isLoading: true,
		deviceList: [],
		data: [],
		center: undefined,
		active: undefined,
	};
	looper = null;
	shadowLooper = null;

	componentDidMount = () => {
		this.props.getAllDevicesInfo();
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.selectedFeed !== this.props.selectedFeed) {
			this.setState({ isLoading: true });
			if (this.props.selectedFeed.type == 'coinexchange') this.loopShadowData();
			if (this.props.selectedFeed.type == 'door') this.loopFeedData();
		}
		if (prevProps.feedData !== this.props.feedData) {
			this.setDataToCharts();
			this.setState({ isLoading: false });
		}
		if (prevProps.shadowData !== this.props.shadowData) {
			this.setCoinData();
			this.setState({ isLoading: false });
		}
	};

	loopFeedData = () => {
		clearInterval(this.looper);
		this.props.getFeedData();
		this.looper = setInterval(() => this.props.getFeedData(), 300000);
	};

	loopShadowData = () => {
		clearInterval(this.shadowLooper);
		this.props.getShadowData();
		this.shadowLooper = setInterval(() => this.props.getShadowData(), 300000);
	};

	submitSelectedRange = (selectMap) => {
		const { startDate, endDate } = this.props.selectedFeed.range;
		const { breakdown } = this.props.selectedFeed;
		const { allLocationInfo } = this.props;
		const location = selectMap.locationid;
		const startDateTimeStamp = moment(startDate).valueOf();
		const endDateTimeStamp = moment(endDate).valueOf();
		const devices = allLocationInfo.find((element) => element.locationid == location).devicesId;
		const type = allLocationInfo.find((element) => element.locationid == location).type;
		this.props.getSelectedFeed(
			devices,
			location,
			{
				startDate: startDateTimeStamp,
				endDate: endDateTimeStamp,
			},
			breakdown,
			type
		);
	};

	setDataToCharts = () => {
		const { currentFeed, revenueFeed, remoteFeed, peopleFeed, fingerPrintFeed, keycardFeed } = this.props.feedData;
		const { location } = this.props.selectedFeed;
		const selectedSiteName =
			this.props.allLocationInfo[
				this.props.allLocationInfo.findIndex((element) => element.locationid == location)
			].name;
		const selectDevice = this.props.allDeviceInfo.filter((device) => device.location == location);
		this.setState({
			currentLast:
				currentFeed.length > 0
					? (Math.round(this.getTotalCurrent(currentFeed) * 100) / 100).toLocaleString('en')
					: '-',
			revenueTotal:
				revenueFeed.length > 0
					? (Math.round(this.getTotal(revenueFeed) * 100) / 100).toLocaleString('en')
					: '-',
			peopleTotal:
				peopleFeed.length > 0 ? (Math.round(this.getTotal(peopleFeed) * 100) / 100).toLocaleString('en') : '-',
			remoteTotal:
				remoteFeed.length > 0 ? (Math.round(this.getTotal(remoteFeed) * 100) / 100).toLocaleString('en') : '-',
			fingerPrintTotal:
				fingerPrintFeed.length > 0
					? (Math.round(this.getTotal(fingerPrintFeed) * 100) / 100).toLocaleString('en')
					: '-',
			keycardTotal:
				keycardFeed.length > 0
					? (Math.round(this.getTotal(keycardFeed) * 100) / 100).toLocaleString('en')
					: '-',
			table: this.getTable(selectedSiteName, [
				peopleFeed,
				revenueFeed,
				remoteFeed,
				fingerPrintFeed,
				keycardFeed,
				currentFeed,
			]),
			deviceList: selectDevice,
		});
	};

	setCoinData = () => {
		const { location } = this.props.selectedFeed;

		this.setState({
			coinExchangeTable: this.getCoinExchangeTable(location, this.props.shadowData.coinExchangeData),
		});
	};

	getMax = (data) => {
		let max = data[0][1];
		for (let i = 1, len = data.length; i < len; i++) {
			let v = data[i][1];
			max = v > max ? v : max;
		}
		return max;
	};

	getMin = (data) => {
		let min = data[0][1];
		for (let i = 1, len = data.length; i < len; i++) {
			let v = data[i][1];
			min = v < min ? v : min;
		}
		return min;
	};

	getAvg = (data) => {
		let avg = data[0][1];
		for (let i = 1, len = data.length; i < len; i++) {
			let v = data[i][1];
			if (!isNaN(v)) avg = avg + v;
		}
		return avg / data.length;
	};

	getTotal = (data) => {
		let total = 0;
		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < data[i].value.length; j++) {
				let v = data[i].value[j][1];
				if (!isNaN(v)) total = total + v;
			}
		}
		return isNaN(total) ? 0 : total;
	};

	getTotalCurrent = (data) => {
		let total = 0;
		for (let i = 0; i < data.length; i++) {
			let v = data[i].value[data[i].value.length - 1][1];
			if (!isNaN(v)) total = total + v;
		}
		return isNaN(total) ? 0 : total;
	};

	getTable = (site, data) => {
		let table = [];
		let feeds = [];
		const breakdown = this.props.selectedFeed.breakdown;
		let feedlist = [];
		for (let data_index = 0; data_index < data.length; data_index++) {
			var allValues = [];
			for (let feed_index = 0; feed_index < data[data_index].length; feed_index++) {
				if (data_index == 0) {
					feedlist[feedlist.length] = data[data_index][feed_index].deviceId;
				}
				for (let index = 0; index < data[data_index][feed_index].value.length; index++) {
					data[data_index][feed_index].value[index] = [
						data[data_index][feed_index].value[index][0],
						data[data_index][feed_index].value[index][1],
						data[data_index][feed_index].deviceId,
					];
				}
				allValues = allValues.concat(data[data_index][feed_index].value);
			}
			let mapValues = [];
			for (let index = 0; index < allValues.length; index++) {
				let key = allValues[index][0];
				if (breakdown == 'minutes') {
					key = moment(parseInt(allValues[index][0])).minutes(
						Math.round(moment(parseInt(allValues[index][0])).minutes() / 5) * 5
					);
				} else if (breakdown == 'hours') {
					key = moment(parseInt(allValues[index][0])).minutes(0);
				} else if (breakdown == 'days') {
					key = moment(parseInt(allValues[index][0])).hours(8).minutes(0);
				} else if (breakdown == 'weeks') {
					key = moment(parseInt(allValues[index][0])).startOf('week').hours(1).minutes(0);
				} else if (breakdown == 'months') {
					key = moment(parseInt(allValues[index][0])).startOf('month').hours(1).minutes(0);
				}
				const timestamp = key.valueOf().toString().substring(0, 8) + '00000';
				if (Object.keys(mapValues).includes(timestamp)) {
					if (feedlist.length === 1) {
						if (data_index === 5) {
							mapValues[timestamp][0] = allValues[index][1];
						} else {
							mapValues[timestamp][0] = mapValues[timestamp][0] + allValues[index][1];
						}
					} else {
						if (data_index === 5) {
							mapValues[timestamp][feedlist.indexOf(allValues[index][2])] = allValues[index][1];
						} else {
							if (mapValues[timestamp][feedlist.indexOf(allValues[index][2])]) {
								mapValues[timestamp][feedlist.indexOf(allValues[index][2])] =
									mapValues[timestamp][feedlist.indexOf(allValues[index][2])] + allValues[index][1];
							} else {
								mapValues[timestamp][feedlist.indexOf(allValues[index][2])] = allValues[index][1];
							}
						}
					}
				} else {
					if (feedlist.length === 1) {
						mapValues[timestamp] = [allValues[index][1]];
					} else {
						if (feedlist.indexOf(allValues[index][2]) === 0) {
							mapValues[timestamp] = [allValues[index][1], 0];
						} else {
							mapValues[timestamp] = [0, allValues[index][1]];
						}
					}
				}
			}
			feeds.push(mapValues);
		}
		Object.keys(feeds[0])
			.sort()
			.map((key) => {
				const row = [];
				for (let i = 0; i < data[0].length; i++) {
					let timestampFormat = '';
					if (breakdown == 'months') {
						timestampFormat = moment(parseInt(key)).format('MMM YYYY');
					} else if (breakdown == 'weeks') {
						timestampFormat =
							moment(parseInt(key)).startOf('week').format('D/M/YY') +
							' - ' +
							moment(parseInt(key)).endOf('week').format('D/M/YY');
					} else if (breakdown == 'days') {
						timestampFormat = moment(parseInt(key)).format('D/M/YY');
					} else if (breakdown == 'hours') {
						timestampFormat = moment(parseInt(key)).format('D/M/YY HH:00');
					} else {
						timestampFormat = moment(parseInt(key)).format('D/M/YY HH:mm');
					}
					let v = {
						site: site,
						people: feeds[0][key] ? feeds[0][key][i] : '',
						revenue: feeds[1][key] ? feeds[1][key][i] : '',
						remote: feeds[2][key] ? feeds[2][key][i] : '',
						fingerPrint: feeds[3][key] ? feeds[3][key][i] : '',
						keycard: feeds[4][key] ? feeds[4][key][i] : '',
						current: feeds[5][key] ? feeds[5][key][i] : '',
						timestamp: timestampFormat,
					};
					row.push(v);
				}
				table.push(row);
			});
		return table;
	};

	getCoinExchangeTable = (location, data) => {
		const devicesInfo = this.props.allDeviceInfo.filter((element) => element.location == location);
		const table = [];
		devicesInfo.map((device) => {
			const value = data.find((query) => query.deviceid == device.deviceid);
			table.push({ name: device.name, onStock: value.onStock, changed: value.changed });
		});

		return table.sort((a, b) => a.name.localeCompare(b.name)) || [];
	};

	getBox = () => {
		const { currentFeed, revenueFeed, peopleFeed, remoteFeed, fingerPrintFeed, keycardFeed } = this.props.feedData;
		const { allLocationInfo, selectedFeed } = this.props;
		if (this.props.selectedFeed && this.props.selectedFeed.type == 'door') {
			const data1 = allLocationInfo.filter((location) => location.type == 'door');
			const { location } = selectedFeed;
			const currentIndex = data1.findIndex((element) => element.locationid == location);

			return (
				<div>
					<Row>
						<Mapbox
							data={data1}
							center={data1[currentIndex].position}
							active={data1[currentIndex]}
							changeLocation={this.submitSelectedRange}
						/>
					</Row>
					<Row>
						<DataCard
							item={Data[0]}
							feedData={currentFeed}
							datavalue={[{ title: 'last', value: this.state.currentLast }]}
							breakdown={this.props.selectedFeed ? this.props.selectedFeed.breakdown : 'minutes'}
						/>
						<DataCard
							item={Data[1]}
							feedData={revenueFeed}
							datavalue={[{ title: 'total', value: this.state.revenueTotal }]}
							breakdown={this.props.selectedFeed ? this.props.selectedFeed.breakdown : 'minutes'}
						/>
						<DataCard
							item={Data[2]}
							feedData={peopleFeed}
							datavalue={[{ title: 'total', value: this.state.peopleTotal }]}
							breakdown={this.props.selectedFeed ? this.props.selectedFeed.breakdown : 'minutes'}
						/>
						<DataCard
							item={Data[3]}
							feedData={remoteFeed}
							datavalue={[{ title: 'total', value: this.state.remoteTotal }]}
							breakdown={this.props.selectedFeed ? this.props.selectedFeed.breakdown : 'minutes'}
						/>
						<DataCard
							item={Data[4]}
							feedData={fingerPrintFeed}
							datavalue={[{ title: 'total', value: this.state.fingerPrintTotal }]}
							breakdown={this.props.selectedFeed ? this.props.selectedFeed.breakdown : 'minutes'}
						/>
						<DataCard
							item={Data[5]}
							feedData={keycardFeed}
							datavalue={[{ title: 'total', value: this.state.keycardTotal }]}
							breakdown={this.props.selectedFeed ? this.props.selectedFeed.breakdown : 'minutes'}
						/>
					</Row>
					<Row>
						<DataTable
							table={this.state.table}
							size={currentFeed ? currentFeed.length : 0}
							deviceList={this.state.deviceList}
							dataType={this.props.selectedFeed.type}
						/>
					</Row>
				</div>
			);
		} else if (this.props.selectedFeed && this.props.selectedFeed.type == 'coinexchange') {
			return (
				<div>
					<Row>
						<DataTable table={this.state.coinExchangeTable} dataType={this.props.selectedFeed.type} />
					</Row>
				</div>
			);
		}
	};

	render() {
		return (
			<>
				<NavBar />

				<Container style={{ padding: '30px', maxWidth: '990px' }}>
					<Row>
						<GetDataSelection loading={this.state.isLoading} />
					</Row>

					{this.getBox()}
				</Container>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		selectedFeed: state.selectedFeedReducer.selectedFeed,
		allDeviceInfo: state.allDevicesInfoReducer.allDeviceInfo,
		allLocationInfo: state.allDevicesInfoReducer.allLocationInfo,
		feedData: state.feedDataReducer,
		shadowData: state.shadowDataReducer,
	};
};

const mapDispatchToProps = {
	getAllDevicesInfo,
	getFeedData,
	getSelectedFeed,
	getShadowData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
