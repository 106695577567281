import React, { Component } from 'react';
import LogInView from './LogInView';
import { withRouter } from 'react-router';
import app from '../../firebase';

class LogInContainer extends Component {
	handleSignUp = async (event) => {
		event.preventDefault();
		const { email, password } = event.target.elements;
		try {
			await app.auth().signInWithEmailAndPassword(email.value, password.value);
			this.props.history.push('/');
		} catch (error) {
			alert(error);
		}
	};

	render() {
		return <LogInView onSubmit={this.handleSignUp} />;
	}
}

export default withRouter(LogInContainer);
