import React, { Component } from 'react';
import { Col, Table, Row, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FaFileCsv } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';


export default class DataTable extends Component {
	state = {
		data: [],
		activePage: 1,
		itemsPerPage: 10,
	};

	handlePageClick = data => {
		let selected = data.selected + 1;
		this.setState({ activePage: selected });
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.table !== this.props.table) {
			let data1 = this.props.table;
			this.setState({
				data: data1,
			});
		}
	};

	render() {
		const { data, activePage, itemsPerPage } = this.state;
		const lastIndex = activePage * itemsPerPage;
		const firstIndex = lastIndex - itemsPerPage;
		const currentItem = data.slice(firstIndex, lastIndex);
		const totalPage = Math.ceil(data.length / itemsPerPage);
		const filename = currentItem.length > 0 && currentItem[0][0] ? currentItem[0][0].site + '.csv' : "coinExchange";
		var renderItem = [];
		var headerItem = [];
		const csvData = []
		if (this.props.dataType == "door") {
// console.log(this.props);
// console.log(this.props.size);
// console.log(this.props.deviceList.length);
// console.log(currentItem);
			if (this.props.deviceList.length <= 1) {
				data.map(value => value.map(v => csvData.push(v)));
			}
			else {
				let header_key = [];
				header_key.push("site");
				for (let i = 0; i < this.props.deviceList.length; i++) {
					if (this.props.deviceList[i]) header_key.push('people_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_"));
				}
				header_key.push("people_total");
				for (let i = 0; i < this.props.deviceList.length; i++) {
					if (this.props.deviceList[i]) header_key.push('revenue_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_"));
				}
				header_key.push("revenue_total");
				for (let i = 0; i < this.props.deviceList.length; i++) {
					if (this.props.deviceList[i]) header_key.push('remote_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_"));
				}
				header_key.push("remote_total");
				for (let i = 0; i < this.props.deviceList.length; i++) {
					if (this.props.deviceList[i]) header_key.push('fingerPrint_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_"));
				}
				header_key.push("fingerPrint_total");
				for (let i = 0; i < this.props.deviceList.length; i++) {
					if (this.props.deviceList[i]) header_key.push('keycard_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_"));
				}
				header_key.push("keycard_total");
				for (let i = 0; i < this.props.deviceList.length; i++) {
					if (this.props.deviceList[i]) header_key.push('current_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_"));
				}
				header_key.push("current_total");
				header_key.push("timestamp");

				data.map(value => {
					let obj = {}
					header_key.forEach(key => {
						obj[key] = 0;
					});
					obj.site = value[0].site;
					for (let i = 0; i < value.length; i++) {
						obj['people_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_")] = value[i].people || 0;
						obj['people_total'] += value[i].people;
						obj['revenue_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_")] = value[i].revenue || 0;
						obj['revenue_total'] += value[i].revenue;
						obj['remote_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_")] = value[i].remote || 0;
						obj['remote_total'] += value[i].remote;
						obj['fingerPrint_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_")] = value[i].fingerPrint || 0;
						obj['fingerPrint_total'] += value[i].fingerPrint;
						obj['keycard_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_")] = value[i].keycard || 0;
						obj['keycard_total'] += value[i].keycard;
						obj['current_' + this.props.deviceList[i].name.replace(/ /g, "_").replace('.', "_")] = value[i].current || 0;
						obj['current_total'] += value[i].current;
					}
					obj.timestamp = value[0].timestamp;
					csvData.push(obj);
				});
			}

			if (this.props.deviceList.length > 1) {
				let tr = [];
				tr.push(<tr>
					<th rowSpan="2">Site</th>
					<th colSpan="3">People</th>
					<th colSpan="3">Revenue</th>
					<th colSpan="3">Remote</th>
					<th colSpan="3">Fingerprint</th>
					<th colSpan="3">Keycard</th>
					<th colSpan="3">Current</th>
					<th rowSpan="2">Timestamp</th>
				</tr>);
				let ths = [];
				
				for (let i = 0; i < 6; i++) {
					for (let j = 0; j < this.props.deviceList.length; j++) {
						if(this.props.deviceList[j]) ths.push(<th>{this.props.deviceList[j] ? this.props.deviceList[j].name : ""}</th>);
					}
					ths.push(<th>Total</th>);
				}
				const row = <tr key={`trtable${tr.length}`}>
					{ths.map(th => th)}
				</tr>
				tr.push(row);
				headerItem = tr.map(t => (t));
			} else {
				headerItem = (<tr>
					<th>Site</th>
					<th>People</th>
					<th>Revenue</th>
					<th>Remote</th>
					<th>Fingerprint</th>
					<th>Keycard</th>
					<th>Current</th>
					<th>Timestamp</th>
				</tr>)
			}
			renderItem = currentItem.map((item, index) => {
				let tds = [];
				tds.push(<td key={`name${index}`}>{item[0] ? item[0].site : ""}</td>);
				let p_total = 0;
				for (let i = 0; i < item.length; i++) {
					tds.push(<td key={`people${index + i}`}>{isNaN(item[i].people) ? 0 : item[i].people}</td>)
					p_total += item[i].people;
				}
				if (item.length > 1) tds.push(<td key={`total_people${index}`}>{isNaN(p_total) ? 0 : p_total}</td>);
				let rev_total = 0;
				for (let i = 0; i < item.length; i++) {
					tds.push(<td key={`revenue${index + i}`}>{isNaN(item[i].revenue) ? 0 : item[i].revenue}</td>)
					rev_total += item[i].revenue;
				}
				if (item.length > 1) tds.push(<td key={`total_revenue${index}`}>{isNaN(rev_total) ? 0 : rev_total}</td>);
				let rem_total = 0;
				for (let i = 0; i < item.length; i++) {
					tds.push(<td key={`remote${index + i}`}>{isNaN(item[i].remote) ? 0 : item[i].remote}</td>)
					rem_total += item[i].remote;
				}
				if (item.length > 1) tds.push(<td key={`total_remote${index}`}>{isNaN(rem_total) ? 0 : rem_total}</td>);
				let fin_total = 0;
				for (let i = 0; i < item.length; i++) {
					tds.push(<td key={`fingerprint${index + i}`}>{isNaN(item[i].fingerPrint) ? 0 : item[i].fingerPrint}</td>)
					fin_total += item[i].fingerPrint;
				}
				if (item.length > 1) tds.push(<td key={`total_fingerprint${index}`}>{isNaN(fin_total) ? 0 : fin_total}</td>);
				let key_total = 0;
				for (let i = 0; i < item.length; i++) {
					tds.push(<td key={`keycard${index + i}`}>{isNaN(item[i].keycard) ? 0 : item[i].keycard}</td>)
					key_total += item[i].keycard;
				}
				if (item.length > 1) tds.push(<td key={`total_keycard${index}`}>{isNaN(key_total) ? 0 : key_total}</td>);
				let c_total = 0;
				for (let i = 0; i < item.length; i++) {
					tds.push(<td key={`current${index + i}`}>{isNaN(item[i].current) ? 0 : item[i].current}</td>)
					c_total += item[i].current;
				}
				if (item.length > 1) tds.push(<td key={`total_current${index}`}>{isNaN(c_total) ? 0 : c_total}</td>);
				tds.push(<td key={`timestamp${index}`}>{item[0] ? item[0].timestamp : ""}</td>);

				return (
					<tr key={index}>
						{tds.map(td => td)}
					</tr>
				);
			});

		} else {
			headerItem = (<tr>
				<th>Site</th>
				<th>On Stock</th>
				<th>Change</th>
			</tr>)

			renderItem = currentItem.map((item, index) =>
				<tr key={index}>
					<td key={`site${index}`}>{item.name}</td>
					<td key={`onstock${index}`}>{item.onStock}</td>
					<td key={`change${index}`}>{item.changed}</td>
				</tr>)
			currentItem.map(item => csvData.push(item));
		}

		return (
			<Col>
				<Row style={{ marginLeft: 1 }}>
					<CSVLink data={csvData} filename={`${filename}`}>
						<Button variant="outline-dark" style={{ marginTop: '.5em' }}>
							<FaFileCsv style={{ margin: '0 2 5 0' }} />
							Export to CSV
						</Button>
					</CSVLink>
				</Row>
				<Table responsive striped bordered hover size="sm" style={{ margin: '1em 0 0 0' }} >
					<thead style={{ backgroundColor: '#49b9ff', color: 'white', textAlign: "center" }}>
						{headerItem}
					</thead>
					<tbody>{renderItem}</tbody>
				</Table>
				<ReactPaginate
					pageCount={totalPage}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					previousLabel={'previous'}
					nextLabel={'next'}
					breakLabel={'...'}
					onPageChange={this.handlePageClick}
					containerClassName={'pagination'}
					pageClassName={'page-item'}
					pageLinkClassName={'page-link'}
					activeClassName={'active'}
					previousClassName={'page-link'}
					nextClassName={'page-link'}
					breakClassName={'page-link'}
				/>
			</Col>
		);
	}
}
