import { db } from '../../firebase';

export const GET_ALL_DEVICE_INFO = 'GET_ALL_DEVICE_INFO';

export const getAllDevicesInfoAction = (allLocationInfo, allLocationId, allDeviceInfo, allDeviceId) => ({
	type: GET_ALL_DEVICE_INFO,
	allLocationInfo,
	allLocationId,
	allDeviceInfo,
	allDeviceId,
});

export const getAllDevicesInfo = () => async (dispatch, getState) => {
	const { fireStoreReducer } = getState();
	const currentUid = fireStoreReducer.fireStoreCurrentUser.uid;

	const locationId = await db
		.collection('membership')
		.doc(currentUid)
		.get();
	let allLocationId = Object.keys(locationId.data());
	let allLocationInfo = [];
	let allDeviceInfo = [];
	let allDeviceId = [];
	for (let i = 0, len = allLocationId.length; i < len; i++) {
		const locationInfo = await db
			.collection('location')
			.doc(allLocationId[i])
			.get();
		let info = locationInfo.data();
		let locationData = {};
		locationData.locationid = allLocationId[i];
		locationData.name = info.name;
		locationData.type = info.type;
		locationData.location = info.location;
		locationData.position = [info.location._long, info.location._lat];
		locationData.devicesId = [];
		let devicesId = Object.keys(info.devices);
		devicesId.forEach(async device => {
			const deviceInfo = await db
				.collection('device')
				.doc(device)
				.get();
			allDeviceId.push(device);
			allDeviceInfo.push({ location: allLocationId[i], deviceid: device, name: deviceInfo.data().name });
			locationData.devicesId.push(device);
		});
		allLocationInfo.push(locationData);

		if (i == allLocationId.length - 1) {
			dispatch(getAllDevicesInfoAction(allLocationInfo.sort((a, b) => a.name.localeCompare(b.name)), allLocationId, allDeviceInfo, allDeviceId));
		}
	}


	// const DeviceId = await db
	// 	.collection('membership')
	// 	.doc(currentUid)
	// 	.get();
	// let allDeviceId = Object.keys(DeviceId.data());
	// let allDeviceInfo = [];
	// for (let i = 0, len = allDeviceId.length; i < len; i++) {
	// 	const deviceInfo = await db
	// 		.collection('device')
	// 		.doc(allDeviceId[i])
	// 		.get();
	// 	let devicedata = deviceInfo.data();
	// 	devicedata.deviceid = allDeviceId[i];
	// 	devicedata.position = [devicedata.location._long, devicedata.location._lat];
	// 	console.log(devicedata);
	// 	allDeviceInfo.push(devicedata);
	// 	if (i == allDeviceId.length - 1) {
	// 		console.log(allDeviceInfo);
	// 		console.log(allDeviceId);
	// 		dispatch(getAllDevicesInfoAction(allDeviceInfo, allDeviceId));
	// 	}
	// }
};
