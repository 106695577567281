import { GET_RANGE_DATA } from '../actions/selectedFeedAction';

const initState = {
	selectedFeed: null,
};

const selectedFeedReducer = (state = initState, action) => {
	switch (action.type) {
		case GET_RANGE_DATA:
			return { ...state, selectedFeed: action.selectedFeed };

		default:
			return state;
	}
};

export default selectedFeedReducer;
